import React from 'react';
import LandingPage from './components/screens/LandingPage';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ApolloClient from "apollo-client"
import { WebSocketLink } from "apollo-link-ws"
import { HttpLink } from "apollo-link-http"
import { split } from "apollo-link"
import { getMainDefinition } from "apollo-utilities"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { ApolloProvider } from "react-apollo"

let log = false;

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      move: null,
      apolloClient: {},
      setupApollo: () => { // Pass in (token) if using a JWT or other auth token
        if (log) { console.log('Setting up the Apollo...') }
        const authLink = setContext(async (_, { headers }) => {
          return {
            headers: {
              ...headers,
              // authorization: `Bearer ${token}`, // Not utilizing user validation
            },
          }
        }),
          wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL,
          httpurl = process.env.REACT_APP_GRAPHQL_URL,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    // Authorization: `Bearer ${token}`, // Not utilizing user validation
                  },
                }
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query)
              return (
                kind === "OperationDefinition" && operation === "subscription"
              )
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          })
        if (log) { console.log('Apollo Client Initialized! ', client) }
        this.setState({ apolloClient: client });
      }
    }
  };

  componentWillMount = () => {
    this.state.setupApollo();
  }

  render() {
    return (
      <ApolloProvider client={this.state.apolloClient}>
        <Router>
          <Route path="/:id/" exact component={LandingPage} />
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
