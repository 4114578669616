import React from 'react';
import Axios from 'axios';
import LyftCall from './screens/LyftCall';
import Override from './screens/Override';
import Status from './screens/Status';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

let log = false;

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "85vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
  imgLogo: {
    height: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
  footer: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    textAlign: "center"
  },
  locationHeader: {
    fontWeight: "bold",
    marginBottom: "-15px",
  },
  profileIcon: {
    height: "45px !important",
  },
  fab: {
    margin: theme.spacing(2),
    height: "150px",
    width: "150px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
  verifyBtn: {
    textTransform: 'none'
  },
  statusText: {
    fontSize: "110%"
  },
  map: {
    display: 'block',
    width: '100%',
    maxWidth: '448px',
    // border: `1px solid ${theme.palette.text.disabled}`,
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

class Validation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      override: false,
      drivers: [],
      sharedRides: [],
      existingRide: null,
    }
  }

  componentWillMount = async () => {
    await this.updateNestedRideInfo();
  }

  componentWillReceiveProps = async (newProps) => {
    await this.updateNestedRideInfo();
  }

  // componentWillReceiveProps = () => console.log("will receive props:", this.props)

  updateNestedRideInfo = async () => {
    if (this.props.move.moveByReturnRideId) await this.setState({ sharedRides: this.props.move.moveByReturnRideId.movesByLyftTriggerId });
    if (this.props.move.lyftride) {
      if (this.props.move.lyftride.activeAttempt) {
        await this.setState({ existingRide: this.props.move.lyftride.activeAttempt })
        if (log) console.log("existingRide:", this.state.existingRide)
      }
    };
    if (log) console.log("sharedRides:", this.state.sharedRides)
    Array.from(this.state.sharedRides || []).forEach(move => {
      this.getDriverProfile(move.parent_move.driver_id)
    })
  }

  // Retrieve driver's profile picture URL and also get back last known lat/lng coordinates
  getDriverProfile = (driver_id) => {
    if (this.state.drivers.find(o => o.id === driver_id)) return;
    if (log) console.log("getDriverProfile driver_id:", driver_id)
    try {
      Axios.post(`${process.env.REACT_APP_ROOT_URL}/.netlify/functions/getDriverProfile`, { driver_id: driver_id })
        .then(res => {
          // if (log) console.log("driverArr:", drivers)
          // res.data.imgURL ? setDrivers(drivers => [...drivers, { id: driver_id, url: res.data.imgURL }]) : setDrivers(drivers => [...drivers, { id: driver_id, url: "https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png" }])
          if (res.data.imgURL) {
            let newDrivers = this.state.drivers;
            newDrivers.push({ id: driver_id, url: res.data.imgURL, latitude: res.data.latitude, longitude: res.data.longitude, location_updated: res.data.location_updated })
            this.setState({
              drivers: newDrivers
            })
          }
        })
    } catch (err) {
      // setDrivers(drivers => [...drivers, { id: driver_id, url: "https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png" }])
      let newDrivers = this.state.drivers;
      newDrivers.push({ id: driver_id, url: "https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png" })
      this.setState({
        drivers: newDrivers
      })
    }
  }

  toggleOverride = () => this.setState({ override: !this.state.override })


  handleValidation = (move) => {
    // sharedRides = move.moveByReturnRideId ? move.moveByReturnRideId.movesByLyftTriggerId : null;
    // existingRide = move.lyftride ? move.lyftride.activeAttempt : null;
    if (log) console.log("sharedRides:", this.state.sharedRides)
    if (log) console.log("existingRide:", this.state.existingRide)

    // sharedRides.forEach(move => {
    //   if (log) console.log("useEffect:", move)
    //   getDriverProfile(move.parent_move.driver_id)
    // }, () => {

    // })

    const parseDriverNames = () => {

      // array of driver names from all shared rides
      const driverArr = this.state.sharedRides.map(move => move.parent_move);
      // array to hold names of drivers other than the current driver
      let sharedRiders = [];
      if (log) console.log("driverArr", driverArr)
      driverArr.forEach((driver, index) => {
        if (log) console.log("driver:", driver)
        if (log) console.log("driver.driver_name:", driver.driver_name)
        if (log) console.log("move:", move)
        // Skip over names that match the parent move's driver
        if (driver.driver_name === move.driver_name) return;
        // Add driver's first name to sharedRiders array
        else {
          sharedRiders.push(
            {
              name: driver.driver_name.split(' ')[0],
              status: driver.status ? `(${driver.status})` : null,
              id: driver.driver_id,
              // iconUrl: getDriverProfile(driver.driver_id)
            }
          );
        }
      })
      // Show that the rider is solo if the sharedRiders array is empty
      if (sharedRiders.length < 1) return <Typography variant="h6">Solo ride, no additional riders</Typography>;
      // Otherwise show the names of drivers sharing the Lyft ride
      else return (
        <>
          <Typography variant="h6">Additional Riders:</Typography>
          {
            sharedRiders.map(rider => {
              if (log) console.log("rendering driver:", rider)
              // if (log) console.log("this.state.drivers.find(o => o.id === rider.id):", this.state.drivers.find(o => o.id === rider.id).url)
              return <Typography variant="h6" key={`driverName-${rider}`}> <img alt="profile img" className={this.props.classes.profileIcon} src={this.state.drivers.find(o => o.id === rider.id) ? this.state.drivers.find(o => o.id === rider.id).url : ""} key={`driverIcon-${rider}`} /> {rider.name} {rider.status}</Typography>
            }
            )
          }
        </>
      );
    };

    if (log) console.log("status validation:", this.handleDriverStatusValidation(Array.from(this.state.sharedRides || [])))
    // Skip over validation when override prop is present
    if (this.state.override) return <LyftCall drivers={this.state.drivers} coords={this.props.coords} classes={this.props.classes} parent_move={move} shared_rides={this.state.sharedRides} override={this.state.override} toggleOverride={this.toggleOverride} parseDriverNames={parseDriverNames} />;

    // Render status page if an existing Lyft ride is present
    else if (this.state.existingRide && this.props.move.lyftride.activeAttempt !== 'canceled') {
      if (log) console.log("existingRide exists:", this.state.existingRide)
      return <Status drivers={this.state.drivers} coords={this.props.coords} classes={this.props.classes} parent_move={move} shared_rides={this.state.sharedRides} existing_ride={this.state.existingRide} toggleOverride={this.toggleOverride} parseDriverNames={parseDriverNames} />;
    }
    // Render Override page if one or more drivers have not arrived at the delivery location
    else if (this.handleDriverStatusValidation(Array.from(this.state.sharedRides || [])).length > 0) return <Override drivers={this.state.drivers} coords={this.props.coords} classes={this.props.classes} parent_move={move} shared_rides={this.state.sharedRides} toggleOverride={this.toggleOverride} parseDriverNames={parseDriverNames} />;
    // Render LyftCall page if a parent move is retrieved and all drivers have arrived
    else return <LyftCall drivers={this.state.drivers} coords={this.props.coords} classes={this.props.classes} parent_move={move} shared_rides={this.state.sharedRides} parseDriverNames={parseDriverNames} />;
  }

  handleDriverStatusValidation = moves => {
    let driversNotArrived = [];
    // Push an obj with driver name and status to driversNotArrived array if driver has not arrived or completed the delivery
    for (let move of moves) {
      if (!["delivery arrived", "delivery successful", "delivery started"].includes(move.parent_move.status)) {
        driversNotArrived.push({ driver: move.parent_move.driver_name, status: move.parent_move.status })
      }
    }
    // Return the array (either empty or with one or more objects)
    return driversNotArrived;
  }

  render() {
    return this.handleValidation(this.props.move)
    // return null
  }

}

export default withStyles(styles)(Validation);