import React from 'react';
import Loading from './Loading';
import Failure from './Failure';
import { Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import Validation from '../Validation';
import Axios from 'axios';
import { geolocated } from "react-geolocated";

let log = false;

const GET_PARENT_MOVE = gql`
  subscription findLyftTriggerId($id: bigint) {
    moves(where: {id: {_eq: $id}}) {
      driver_name
      driver_id
      lane {
        description
        pickup {
          name
          address
        }
        delivery {
          name
          address
        }
      }
      status
      return_ride_id
      lyftride {
        move_id
        activeAttempt {
          move_id
          status
          driver_phone
          driver_vehicle_color
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_year
          driver_first_name
          driver_rating
          ride_distance
          ride_duration
          createdat
          updatedat
        }
      }
      moveByReturnRideId {
        lyft_trigger_id
        lane {
          description
          pickup {
            name
            address
            latitude
            longitude
          }
          delivery {
            name
            address
            latitude
            longitude
          }
        }
        movesByLyftTriggerId {
          parent_move {
            id
            status
            driver_name
            driver_id
          }
        }
      }
    }
  }`;

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      decryptedID: null
    }
  }

  componentWillMount = async () => {
    if (log) console.log("LandingPage mounted")
    try {
      await Axios.post(`${process.env.REACT_APP_ROOT_URL}/.netlify/functions/hashDecrypt`, { id: this.props.match.params.id })
        .then(res => {
          this.setState({ decryptedID: res.data.id })
        })
    } catch (err) {
      alert("Error occurred, please contact dispatcher.")
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          !this.state.decryptedID ? null :
            <Subscription
              subscription={GET_PARENT_MOVE}
              variables={{ id: this.state.decryptedID }}
            >
              {(result) => {
                if (result.loading) return <Loading />;
                if (result.error) return <Failure />;
                if (result.data) {
                  if (!result.variables.id || result.variables.id === "") return <Failure />;
                  if (log) console.log("parent move subscription response:", result);
                  return <Validation move={result.data.moves[0]} coords={this.props.coords} />
                }
              }}
            </Subscription>
        }
      </React.Fragment>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(LandingPage);