import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import GoogleStaticMapURL from '../utils/GoogleStaticMapUrl';



const VerifyPickup = (props) => {

    const staticMapUrl = () => GoogleStaticMapURL.build({
        key: process.env.REACT_APP_GOOGLE_STATIC_MAPS_KEY,
        markers: [
            {
                icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
                shadow: true,
                lat: props.coords.latitude,
                lon: props.coords.longitude,
            },
        ],
        center: 'auto',
        zoom: '19',
        scale: false,
        size: '448x248',
        maptype: 'roadmap',
        format: 'png',
        visual_refresh: true
    });

    return (
        <Grid container justify="center" alignItems="center" direction="row" spacing={2}>

            <Grid item xs={12}>
                <Typography>You are outside of the known pickup area, please verify your pickup location: </Typography>
            </Grid>

            <Grid item xs={6}>
                <Button variant='contained' size='small' className={props.classes.verifyBtn} color='secondary' onClick={() => props.handleValidateLocation(true)}>Current Location (below)</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant='contained' size='small' className={props.classes.verifyBtn} color='secondary' onClick={() => props.handleLyftCall()}>{props.pickup.name}</Button>
            </Grid>

            <Grid item xs={12}>
                <img className={props.classes.map} src={staticMapUrl()} alt={`lane-img`} />
            </Grid>

        </Grid>

    )
}

export default VerifyPickup;