import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import Footer from '../Footer';

const satLogo = require('../../static/logo.png');

const Override = (props) => {

  return (
    <div className={props.classes.root}>

      <Grid container justify="center" alignItems="center" direction="row" spacing={2}>

        <Grid item xs={12}>
          {/* <Paper className={props.classes.paper}>xs=12</Paper> */}
          <img className={props.classes.imgLogo} src={satLogo} alt="Social Auto Transport"></img>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>Your Lyft Return Ride</Typography>
          <Typography>
            <a
              href={`http://maps.google.com/maps?saddr="${props.parent_move.moveByReturnRideId.lane.pickup.address}"&daddr=${props.parent_move.moveByReturnRideId.lane.delivery.address}`}
            >
              {props.parent_move.moveByReturnRideId.lane.description}
            </a>
          </Typography>
          {props.parseDriverNames(Array.from(props.shared_rides || []))}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" style={{ fontWeight: "bold" }}>Not all drivers have arrived, do you want to continue?</Typography>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" style={{ backgroundColor: "green" }} className={props.classes.button} onClick={() => props.toggleOverride()}>
            Yes
          </Button>
        </Grid>

      </Grid>

    <Footer />

    </div>
  );
}

export default Override;