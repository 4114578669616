import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import Footer from '../Footer';
import Loading from './Loading';
import axios from 'axios';

const satLogo = require('../../static/logo.png');

const Status = (props) => {

  const [retry, setRetry] = useState(false)

  const handleAwaitingResponse = () => {
    setTimeout(() => setRetry(true), 6000);
    return retry ? <Button className={props.classes.button} variant="contained" color="secondary" onClick={() => handleRetry(props.parent_move.lyftride.move_id)}>No response, retry?</Button> : null;
  }

  const handleRetry = async move_id => {
    setRetry(false);
    await axios({
      url: process.env.REACT_APP_GRAPHQL_URL,
      method: 'post',
      data: {
        query: `
          mutation updateActiveAttempt(
            $move_id: bigint!
            $attempt: bigint
          ) {
            update_lyftrides(where: {move_id: {_eq: $move_id}}, _set: {active_attempt: $attempt, updatedat: "now()"}) {
              affected_rows
              returning {
                active_attempt
              }
            }
          }`,
        variables: { move_id: move_id, attempt: null }
      },
      headers: {
        'content-type': 'application/json',
      },
    }).then(res => {
      props.toggleOverride();
    });
  }

  return (
    <div className={props.classes.root}>
      <Grid container justify="center" alignItems="center" direction="row" spacing={2}>

        <Grid item xs={12}>
          <img className={props.classes.imgLogo} src={satLogo} alt="Social Auto Transport"></img>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>Your Lyft Return Ride</Typography>
          <Typography>
            <a
              href={`http://maps.google.com/maps?saddr="${props.parent_move.moveByReturnRideId.lane.pickup.address}"&daddr=${props.parent_move.moveByReturnRideId.lane.delivery.address}`}
            >
              {props.parent_move.moveByReturnRideId.lane.description}
            </a>
          </Typography>
          {props.parseDriverNames(Array.from(props.shared_rides || []))}
        </Grid>
        <Grid item xs={6}>
          <Typography className={props.classes.locationHeader}>Pickup</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={props.classes.locationHeader}>Delivery</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <a
              href={`https://maps.google.com/?q="${props.parent_move.moveByReturnRideId.lane.pickup.address}"`}
            >
              {props.parent_move.moveByReturnRideId.lane.pickup.name}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <a
              href={`https://maps.google.com/?q="${props.parent_move.moveByReturnRideId.lane.delivery.address}"`}
            >
              {props.parent_move.moveByReturnRideId.lane.delivery.name}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Current status:</Typography>
        </Grid>

        <Grid item xs={12}>
          {
            props.existing_ride.status === 'awaitingResponse'
              ? !retry ? <Loading /> : null
              : <Typography variant="h4">{props.existing_ride.status || "Ride pending"}</Typography>
          }
          {/* <Typography variant="h4">{props.existing_ride.status || "Ride pending"}</Typography> */}
        </Grid>

        {
          props.existing_ride.status === 'awaitingResponse'
            // ? 
            ? handleAwaitingResponse()
            : null
        }

        {
          props.existing_ride.status === 'canceled'
            ? <Button className={props.classes.button} variant="contained" color="primary" onClick={() => props.toggleOverride()}> Retry </Button>
            : props.existing_ride.driver_first_name ?
              <Grid item xs={12}>
                <Typography className={props.classes.statusText}>Driver: {props.existing_ride.driver_first_name}</Typography>
                <Typography className={props.classes.statusText}>Phone:
                <a
                    href={`tel:${props.existing_ride.driver_phone}`}
                    style={{ marginLeft: "3px" }}
                  >
                    {props.existing_ride.driver_phone}
                  </a>
                </Typography>
                <Typography className={props.classes.statusText}>{`Vehicle: ${props.existing_ride.driver_vehicle_color} ${props.existing_ride.driver_vehicle_make} ${props.existing_ride.driver_vehicle_model}`}</Typography>
                <Typography className={props.classes.statusText}>License plate: {props.existing_ride.driver_vehicle_license_plate}</Typography>
              </Grid>
              : null
        }


      </Grid>

      <Footer />

    </div >
  );
}

export default Status;