import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const satLogo = require('../../static/logo.png');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "85vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
  imgLogo: {
    height: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
  footer: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    textAlign: "center"
  },
  locationHeader: {
    fontWeight: "bold",
    marginBottom: "-15px",
  }
}));

const Failure = (props) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      
      <Grid container justify="center" alignItems="center" direction="row" spacing={2}>

        <Grid item xs={12}>
          <img className={classes.imgLogo} src={satLogo} alt="Social Auto Transport"></img>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Failed to retrieve ride info. Please contact dispatch:</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">
            <a href={`tel:800-913-7674`}>800-913-7674</a>
          </Typography>
        </Grid>

      </Grid>

    </div>
  );
}

export default Failure;