import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  footer: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    textAlign: "center"
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  return <footer className={classes.footer}>
    <Typography style={{ textAlign: "center" }}>Call dispatcher:
    <a
        href={`tel:800-913-7674`}
        style={{ marginLeft: "3px" }}
      >
        800-913-7674
      </a>
    </Typography>
    <Typography style={{ textAlign: "center" }}>Text dispatcher:
    <a
        href={`sms:804-487-3935`}
        style={{ marginLeft: "3px" }}
      >
        804-487-3935
      </a>
    </Typography>
  </footer>
}