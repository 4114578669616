import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Fab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLyft } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
// import Status from './Status';
import Footer from '../Footer';
import Loading from './Loading';
import VerifyPickup from './VerifyPickup';
import GetDistance from '../utils/GetDistance';

const satLogo = require('../../static/logo.png');

let log = false;

const INSERT_NEW_LYFT_RIDE = `
  mutation newLyftRide(
    $id: bigint!
    $latitude: numeric
    $longitude: numeric
    $location_type: String
    ) {
    insert_lyftrides(objects: {
      move_id: $id
      latitude: $latitude
      longitude: $longitude
      pickup_location_type: $location_type
    } on_conflict: {
      constraint: lyftrides_move_id_key, update_columns: last_attempt
    }) {
      returning {
        move_id
        last_attempt
      }
    }
  }
`;

const LyftCall = (props) => {

  const [loading, setLoading] = useState(false);
  const [pickupNeedsVerify, setpickupNeedsVerify] = useState(false);

  const handleValidateLocation = (override = false) => {

    let currentLoc = null;
    let location_type = 'database coords';

    // Check user's current coordinates against the pickup location
    try {
      if (log) console.log("Setting location by GPS...")
      if (props.coords) {
        if (props.coords.latitude && props.coords.longitude) {
          currentLoc = [props.coords.latitude, props.coords.longitude]
          location_type = 'phone coords'
        }
      }
      if (!currentLoc) {
        if (log) console.log("Could not locate based on phone's GPS, using Tookan's last known location...")
        let driver = props.drivers.find(o => o.id === props.parent_move.driver_id);
        // Check if Tookan's last known location is within the tolerance defined in ENV var
        let difInSec = Math.round(Date.now() / 1000) - Math.round(new Date(driver.location_updated).getTime() / 1000);
        if (difInSec <= process.env.REACT_APP_TOOKAN_COORD_BUFFER) {
          if (log) console.log("Location has been updated within the allowed tolerance of", process.env.REACT_APP_TOOKAN_COORD_BUFFER, "seconds")
          currentLoc = [driver.latitude, driver.longitude]
          location_type = 'tookan coords'
        } else {
          if (log) console.log("Location has not been updated within the allowed tolerance of", process.env.REACT_APP_TOOKAN_COORD_BUFFER, "seconds, using the default pickup location coords...")
          currentLoc = [props.parent_move.moveByReturnRideId.lane.pickup.latitude, props.parent_move.moveByReturnRideId.lane.pickup.longitude]
          location_type = 'database coords'
        }
      }
    } catch (err) {
      if (log) console.log('Failed to get current location')
      console.log(err)
      alert('Could not determine current location, please contact your dispatcher.')
      return;
    }

    if (override) {
      if (log) console.log("Override - calling Lyft with current location...")
      handleLyftCall(currentLoc, location_type)
    } else {
      const pickupLoc = [props.parent_move.moveByReturnRideId.lane.pickup.latitude, props.parent_move.moveByReturnRideId.lane.pickup.longitude];
      const distanceFromPickupLoc = GetDistance(currentLoc, pickupLoc, true);

      if (log) console.log("Current coords:", [props.coords.latitude, props.coords.longitude])
      if (log) console.log("Pickup location coords:", pickupLoc)
      if (log) console.log("Distance from pickup location:", distanceFromPickupLoc.toFixed(1), 'mi')

      if (distanceFromPickupLoc > Number(process.env.REACT_APP_COORD_DISTANCE_MILES)) {
        if (log) console.log("Current location is outside of allowed bounds...")
        if (log) console.log("distanceFromPickupLoc:", distanceFromPickupLoc, "allowed:", Number(process.env.REACT_APP_COORD_DISTANCE_MILES))
        setpickupNeedsVerify(true);
      } else {
        if (log) console.log("Current location is within allowed bounds...")
        if (log) console.log("distanceFromPickupLoc:", distanceFromPickupLoc, "allowed:", Number(process.env.REACT_APP_COORD_DISTANCE_MILES))
        handleLyftCall(currentLoc, location_type)
      }
    }

  }

  const handleLyftCall = async (coords = null, location_type = 'database coords') => {
    setpickupNeedsVerify(false)
    setLoading(true);
    try {
      // Insert new row into the lyftrides table
      await axios({
        url: process.env.REACT_APP_GRAPHQL_URL,
        method: 'post',
        data: {
          query: INSERT_NEW_LYFT_RIDE,
          variables: {
            id: props.parent_move.moveByReturnRideId.lyft_trigger_id,
            latitude: coords ? coords[0] : null,
            longitude: coords ? coords[1] : null,
            location_type: location_type
          }
        },
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(res => {
          if (res.data.data.insert_lyftrides.returning[0] && res.data.data.insert_lyftrides.returning[0].move_id) {
            if (props.override) props.toggleOverride()
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <div className={props.classes.root}>
      <Grid container justify="center" alignItems="center" direction="row" spacing={2}>

        <Grid item xs={12}>
          <img className={props.classes.imgLogo} src={satLogo} alt="Social Auto Transport"></img>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>Your Lyft Return Ride</Typography>
          <Typography>
            <a
              href={`http://maps.google.com/maps?saddr="${props.parent_move.moveByReturnRideId.lane.pickup.address}"&daddr=${props.parent_move.moveByReturnRideId.lane.delivery.address}`}
            >
              {props.parent_move.moveByReturnRideId.lane.description}
            </a>
          </Typography>
          {props.parseDriverNames(Array.from(props.shared_rides || []))}
        </Grid>

        <Grid item xs={6}>
          <Typography className={props.classes.locationHeader}>Pickup</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={props.classes.locationHeader}>Delivery</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <a
              href={`https://maps.google.com/?q="${props.parent_move.moveByReturnRideId.lane.pickup.address}"`}
            >
              {props.parent_move.moveByReturnRideId.lane.pickup.name}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            <a
              href={`https://maps.google.com/?q="${props.parent_move.moveByReturnRideId.lane.delivery.address}"`}
            >
              {props.parent_move.moveByReturnRideId.lane.delivery.name}
            </a>
          </Typography>
        </Grid>
        {
          !pickupNeedsVerify ?
            (
              !loading ?
                <>
                  <Grid item xs={12}>
                    <Fab color="secondary" aria-label="call" className={props.classes.fab} onClick={() => handleValidateLocation()}>
                      <FontAwesomeIcon style={{ transform: "scale(2.75)", marginTop: "8px" }} icon={faLyft} />
                    </Fab>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography style={{ textAlign: "center", fontWeight: "bold", marginTop: "-20px" }} variant="h5">Call Lyft</Typography>
                  </Grid>
                </>
                : <Loading />
            ) : (
              <Grid item xs={12}>
                <VerifyPickup
                  pickup={props.parent_move.moveByReturnRideId.lane.pickup}
                  pickup_coords={[props.parent_move.moveByReturnRideId.lane.pickup.latitude, props.parent_move.moveByReturnRideId.lane.pickup.longitude]}
                  coords={props.coords}
                  classes={props.classes}
                  handleLyftCall={handleLyftCall}
                  handleValidateLocation={handleValidateLocation}
                />
              </Grid>
            )
        }
      </Grid>

      <Footer />

    </div>
  )
}

export default LyftCall;